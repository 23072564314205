import "./import-jquery";
import '@popperjs/core';
import 'bootstrap';
import '@fancyapps/fancybox';
// import 'jquery-focuspoint';
import './bootstrap.scss'
import './custom.scss'
import _ from "underscore";

// set variables
var xs;
var sm;
var md;
var lg;
var xl;
var breakpoint;

/**
 * forEach implementation for Objects/NodeLists/Arrays, automatic type loops and context options
 *
 * @private
 * @author Todd Motto
 * @link https://github.com/toddmotto/foreach
 * @param {Array|Object|NodeList} collection - Collection of items to iterate, could be an Array, Object or NodeList
 * @callback requestCallback      callback   - Callback function for each iteration.
 * @param {Array|Object|NodeList} scope=null - Object/NodeList/Array that forEach is iterating over, to use as the this value when executing callback.
 * @returns {}
 */
var lm_forEach=function(t,o,r){if("[object Object]"===Object.prototype.toString.call(t))for(var c in t)Object.prototype.hasOwnProperty.call(t,c)&&o.call(r,t[c],c,t);else for(var e=0,l=t.length;l>e;e++)o.call(r,t[e],e,t)};


// Checks if the span is set to display lock via CSS
function checkIfBlock (target) {
    var target = $(target).css('display') == 'block';
    return target;
}

// function to check the sizes
function checkSize (){
    // Set some variables to use with the if checks below

    xs = checkIfBlock('.breakpoint-check .xs');
    sm = checkIfBlock('.breakpoint-check .sm');
    md = checkIfBlock('.breakpoint-check .md');
    lg = checkIfBlock('.breakpoint-check .lg');
    xl = checkIfBlock('.breakpoint-check .xl');

// add the breakpoint to the console
// console.clear();
    if( xs == true) {
        breakpoint = "xs - <576px";
        // console.log(breakpoint);
        $("body").removeClass('xs sm md lg xl').addClass( "xs" );
    }

    if( sm == true) {
        breakpoint = "sm - ≥576px";
        // console.log(breakpoint);
        $("body").removeClass('xs sm md lg xl').addClass( "sm" );
    }

    if( md == true) {
        breakpoint = "md - ≥768px";
        // console.log(breakpoint);
        $("body").removeClass('xs sm md lg xl').addClass( "md" );
    }

    if( lg == true) {
        breakpoint = "lg - ≥992px";
        // console.log(breakpoint);
        $("body").removeClass('xs sm md lg xl').addClass( "lg" );
    }

    if( xl == true) {
        breakpoint = "xl - ≥1200px";
        // console.log(breakpoint);
        $("body").removeClass('xs sm md lg xl').addClass( "xl" );
    }

}
// end check size

$(document).ready(function(){
    // Add some invisible elements with Bootstrap CSS visibile utility classes
    $( "body" ).append( "<div style='display:none;' class='breakpoint-check'><span class='xs d-block d-sm-inline'></span><span class='sm d-sm-block d-md-inline'></span><span class='md d-md-block d-lg-inline'></span><span class='lg d-lg-block d-xl-inline'></span><span class='xl d-xl-block'></span></div>" );
    checkSize();
});


// Reload demo on  window resize
$( window ).resize( function(){
    checkSize();
});


$(function () {

    // Hide some elements ad js is usable:
    $('.hidden-if-js').addClass('d-none');

    // Set background attachment property of main background image to scroll for ios devices
    // as they do not support fixed style.
    if(ds81_is_iOS())
    {
        $('.bgimage').addClass('bg-not-attached');
    }

    // Brand logo scroll to top:
    $('.brand-logo').on('click', function(e){
        e.preventDefault();
        $('body,html').animate({
            scrollTop: 0
        }, 300/$(window).scrollTop());
    });

    // Set image heights:
    $(window).on('resize.ds81setImageHeights', dsSetImageHeights);
    dsSetImageHeights();

    // Init header dynamics on scroll:
    $(window).on('scroll.ds81_header_dynamics', ds81_header_dynamics);

    // Load google map content after consent button click:
    $('.btn-standort-consent-load').on('click', ds81_map_load_content);

    $('[data-fancybox]').fancybox({
        afterShow: function( instance, current ) {
            $('.btn-standort-consent-load').on('click', ds81_map_load_content);

        }
    });

});

function ds81_map_load_content(){

    // Load and render template:
    var tplHtml = $('.standort-data-after-consent').first().html();
    var tpl = _.template(tplHtml);
    var tplRendered = tpl({});

    // Update fancybox content:
    $.fancybox.getInstance().setContent( $.fancybox.getInstance().current, tplRendered);

    $('.btn-standort-consent-load').off('click');
    $('.btn-standort-consent-load').on('click', ds81_map_load_content);

}


function getBreakPointName() {
    if(typeof breakpoint === 'undefined')
    {
        return 'lg';
    }
    return breakpoint.substr(0,2);
}

function isWithinBreakpoint(checkAgainst)
{
    return getBreakPointName() === checkAgainst.toLowerCase();
}


function dsSetImageHeights()
{
    // Adjust images with height reference:
    $('[data-adjust-image-height-ref]').each(function () {
        var $root = $(this).parent();
        if(typeof $(this).data('adjust-image-height-root') !== "undefined")
        {
            $root = $(this).closest($(this).data('adjust-image-height-root'));
        }
        var $ref = $root.find('[data-height-for]').first();
        if($ref.length > 0)
        {
            $(this).css('height', $ref.innerHeight() + 'px');
        }

    });

    // Migrate to bg image if no focuspoint:
    $('[data-i-height-xs],[data-i-height-sm],[data-i-height-md],[data-i-height-lg],[data-i-height-xl]').each(function () {
        if(!$(this).hasClass('focuspoint') && !$(this).hasClass('square-bg'))
        {
            $(this).addClass('square-bg');
            $img = $(this).find('img').first();
            if($img.length > 0)
            {
                var imgSrc = $img.attr('src');
                $picture = $img.closest('picture');
                if($picture.length > 0)
                {
                    if($('html').hasClass('webp'))
                    {
                        $webp = $picture.find('[type="image/webp"]');
                        if($webp.length > 0)
                        {
                            imgSrc = $webp.attr('src');
                        }
                    }
                }
                $(this).css('background-image', 'url(' + imgSrc + ')');
                $img.css('visibility', 'hidden');
            }
        }
    });

    // Set image heights accoring to breakpoint (supersedes height reference);
    $('[data-i-height-xs],[data-i-height-sm],[data-i-height-md],[data-i-height-lg],[data-i-height-xl]').css('overflow-y', 'visible');
    var bb = getBreakPointName();
    switch(bb)
    {
        case('xs'):
            $('[data-i-height-xs]').each(function () {
                $(this).css('height', $(this).data('i-height-xs') + 'px');
            });
            break;
        case('sm'):
            $('[data-i-height-sm]').each(function () {
                $(this).css('height', $(this).data('i-height-sm') + 'px');
            });
            break;
        case('md'):
            $('[data-i-height-md]').each(function () {
                $(this).css('height', $(this).data('i-height-md') + 'px');
            });
            break;
        case('lg'):
            $('[data-i-height-lg]').each(function () {
                $(this).css('height', $(this).data('i-height-lg') + 'px');
            });
            break;
        default:
            $('[data-i-height-xl]').each(function () {
                $(this).css('height', $(this).data('i-height-xl') + 'px');
            });
            break;
    }
    $('[data-i-height-xs],[data-i-height-sm],[data-i-height-md],[data-i-height-lg],[data-i-height-xl]').css('overflow-y', 'hidden');

    // Activate focuspoint on images:
    // $('.focuspoint').focusPoint();

    // Trigger re-init on image load:
    $('img').each(function () {
        if(!$(this).hasClass('load-init') && !$(this).hasClass('loaded'))
        {
            $(this).addClass('load-init');
            $(this).one("load", function () {
                if(this.complete) {
                    $(this).addClass('loaded').removeClass('load-init');
                    dsSetImageHeights();
                }
            });
        }
    });

}


function ds81_header_dynamics(initValue)
{

    // Get values:
    let scrollTop = $(window).scrollTop();
    let $wrapper = $('section.overlay-text').first();
    let $mover = $wrapper.find('.mover').first();
    let scrollPercent = (100 / $wrapper.outerHeight()) * scrollTop * 1.3;
    let opacityLoss = scrollPercent / 100;
    let targetOpacity = 1 - (opacityLoss);
    if(targetOpacity < 0) targetOpacity = 0;
    if(targetOpacity > 1) targetOpacity = 1;
    $mover.css('opacity', targetOpacity);
    $mover.css('top', scrollPercent + '%');

    $mover.find('.move-1').css('height', (scrollPercent * .3) + 'px');
    $mover.find('.move-2').css('height', (scrollPercent * .5) + 'px');

}


function ds81_is_iOS() {
    return [
            'iPad Simulator',
            'iPhone Simulator',
            'iPod Simulator',
            'iPad',
            'iPhone',
            'iPod'
        ].includes(navigator.platform)
        // iPad on iOS 13 detection
        || (navigator.userAgent.includes("Mac") && "ontouchend" in document)
}
